.card {
  min-width: rem-calc(305px);
  min-height: rem-calc(265px);
  position: relative;
  display: flex;
  flex-direction: column;
  background-color: $gray-700;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border-radius: rem-calc($radius);

  &-inner {
    @extend .px-3, .py-3;
    margin-top: auto;
  }

  &-img {
    width: 100%;
    height: 100%;
    object-fit: contain;

    &-wrapper {
      height: 190px;
      padding: 10px;
    }
  }

  &-title {
    font-weight: $font-weight-bold;
    text-transform: capitalize;
  }

  &-price {
    @include position(absolute, rem-calc($sp-base * 2), rem-calc($sp-base * 2));
    display: flex;
    align-items: center;
    padding: 1px rem-calc($sp-base);
    font-size: rem-calc(18px);
    background-color: $gray-400;
    border-radius: rem-calc($radius-rounded);

    span {
      margin-left: rem-calc($sp-base / 2);
      font-size: rem-calc($font-size-xs);
    }
  }

  &-btn {
    width: 100%;
    margin-top: rem-calc($sp-base * 2);
    text-transform: capitalize;
    font-weight: $font-weight-bold;
  }

  &-wrapper {
    display: grid;
    grid-gap: rem-calc($sp-base * 2);

    /* 992px to 1200px */
    @include media-breakpoint-between(md, lg) {
      grid-template-columns: repeat(2, 1fr);
    }

    /* 1200px and bigger */
    @include media-breakpoint-up(lg) {
      grid-template-columns: repeat(3, 1fr);
    }

  }
}