.table {
  width: 100%;

  thead th {
    padding-bottom: rem-calc($sp-base * 4);
  }

  tbody td {
      border-bottom: 1px solid $table-border-color;
  }

  th,
  td {
    vertical-align: middle;

    > div {
      padding: rem-calc(12px);
    }

    span {
      white-space: nowrap;
    }
  }

  &-responsive {
    display: block;
    margin-block: rem-calc($sp-base * 7) rem-calc($sp-base * 3);
    overflow-x: auto;
  }
}
