.loading {
  @include position(fixed, 0, 0, 0, 0, 99);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: #030016;

  &::after {
    content: "";
    min-width: rem-calc(220px);
    min-height: rem-calc(220px);
    @include position(absolute, 50%, $left: 50%);
    transform: translate(-50%, -50%);
    border-radius: $radius-circle;
    background-color: #262270;
    filter: blur(102px);
  }

  .svg-loader {
    width: rem-calc(130px);
    height: rem-calc(130px);
    display: inline-block;

    path {
      fill: $white;
    }
  }
}
