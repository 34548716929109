.curved-text {
  @include position(absolute, -5%, $zIndex: 2);

  path {
    fill: transparent;
  }

  text {
    fill: url(#rainbow);
    @include font($font-family-3d, $font-weight-bold, $font-size-lg * 1.5);
    text-transform: uppercase;
    letter-spacing: em-calc(1px);
    text-shadow: 0 1px 0 #c93100;
  }
}