@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 100%, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes fadeInLeft {
  from {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  to {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}

@keyframes rectDashAnimation {
  from {
    stroke-dasharray: 0 1000;
  }
  to {
    stroke-dasharray: 1000 0;
  }
}

@keyframes lineDashedAnimation {
  from {
    stroke-dasharray: 0;
  }
  to {
    stroke-dasharray: 8;
    opacity: 1;
  }
}

@keyframes lineUnlockedAnimation {
  from {
    opacity: 1;
    stroke-dashoffset: 1000;
  }
  to {
    opacity: 1;
    stroke-dashoffset: 0;
  }
}

@keyframes cityAnimation {
  0% {
    transform: scale(1);
    filter: grayscale(1);
  }
  30% {
    filter: grayscale(1);
  }
  50% {
    transform: scale(1.2);
  }
  100% {
    transform: scale(1);
    filter: none;
  }
}
