.city-item {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: relative;
  @extend .py-4;
  cursor: pointer;
  background-color: $body-bg;

  &-row {
    display: grid;
    grid-template-columns: repeat(4, rem-calc(200px));
    grid-gap: rem-calc(85px) rem-calc(100px);
    justify-content: center;
    position: relative;
    margin-top: rem-calc(115px);

    &-line {
      @include position(absolute, 39%, $zIndex: -1);

      .path {
        stroke-dasharray: 8;
        stroke-dashoffset: 8;
        opacity: 0;
        animation: lineDashedAnimation 1s ease 1s alternate forwards;
      }

      &.right {
        transform: rotate(180deg);
        right: -4%;
      }

      &.left {
        left: -4%;
      }
    }

    &:last-child &-line {
      display: none;
    }

    &:nth-child(even) {
      .city-item-line {
        left: 97%;
      }

      .city-item:last-of-type .city-item-line {
        display: none;
      }
    }

    &:nth-child(odd) {
      .city-item-line {
        right: 97%;
      }

      .city-item:first-of-type .city-item-line {
        display: none;
      }
    }

    &.unlocked-row {
      .city-item-row-line .path {
        stroke-dasharray: 1000;
        stroke-dashoffset: 1000;
        opacity: 0;
        stroke: #65d1ff;
        animation: lineUnlockedAnimation 1s ease 1s alternate forwards;
      }
    }
  }

  &-img {
    width: 100%;
    height: 100%;
    object-fit: contain;
    filter: grayscale(1);
    transition: filter .3s ease;

    &-wrapper {
      width: rem-calc(120px);
      @include position(relative, $zIndex: 1);
    }
  }

  &-box {
    @include position(absolute, $right: -10%, $bottom: -15%);

    &-count {
      width: rem-calc(23px);
      height: rem-calc(23px);
      display: flex;
      justify-content: center;
      align-items: center;
      @include position(absolute, $right: 18%, $bottom: 25%);
      color: $white;
      background-color: $danger;
      border-radius: $radius-circle;
    }

    img {
      width: rem-calc(70px);
    }
  }

  &-body {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &-line {
    @include position(absolute, 39%, 97%, 0, $zIndex: -1);

    .path {
      stroke-dasharray: 8;
      stroke-dashoffset: 8;
      opacity: 0;
      animation: lineDashedAnimation 1s ease 1s alternate forwards;
    }
  }

  &-rect-line {
    @include position(absolute, 0, 0, 0, 0);
    opacity: 0;
  }

  &-score {
    width: 70%;
    height: rem-calc(16px);
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-around;
    margin-top: rem-calc($sp-base * 2);
  }

  &-name {
    margin-top: rem-calc($sp-base);
    text-align: center;
  }

  &-btn {
    min-width: rem-calc(108px);
    margin-top: rem-calc($sp-base * 2);
  }

  &.unlocked {
    .city-item {
      &-img {
        filter: none;

        &-wrapper::after {
          content: "";
          width: 150%;
          height: 70%;
          @include position(absolute, $bottom: rem-calc(-10px), $left: 50%, $zIndex: -1);
          transform: translateX(-50%);
          background: radial-gradient(50% 50% at 50% 50%, $gray-200 0%, rgba(95, 89, 151, 0) 100%);
        }
      }

      &-line {
        .path {
          stroke: #65d1ff;
        }

        .path {
          stroke-dasharray: 1000;
          stroke-dashoffset: 1000;
          animation: lineUnlockedAnimation 1s ease 1s alternate forwards;
        }
      }
    }
  }

  &.animation-city .city-item-img {
    animation: cityAnimation ease 3.5s;
  }

  &:hover,
  &.current {
    .city-item {
      &-rect-line {
        opacity: 1;

        .rect {
          stroke-dasharray: 1000;
          stroke-dashoffset: 700;
          animation: rectDashAnimation 1s ease alternate forwards;
        }
      }

      &-btn {
        @extend .btn-primary;
      }

      //&-curved-text {
      //  animation: fadeInUp .5s ease .5s forwards;
      //}
    }
  }

  &:hover .city-item-rect-line .rect {
    animation: rectDashAnimation .5s ease alternate forwards;
  }

  &:hover {
    .city-item {
      &-img {
        filter: none;
      }
    }
  }

  /* 1440px and bigger */
  @include media-breakpoint-up(lg) {
    &-row {
      grid-gap: rem-calc(80px) rem-calc(150px);
    }
  }

  /* 992px and smaller */
  @include media-breakpoint-down(md) {
    &-row {
      grid-template-columns: repeat(2, rem-calc(200px));
      justify-content: space-between;
      margin-top: rem-calc(50px);

      .city-item-line {
        width: 150%;
      }

      &-line {
        top: 100%;
        transform: rotate(90deg) !important;

        &.right {
          right: 3%;
        }

        &.left {
          left: 3%;
        }
      }
    }
  }

  /* 575px and smaller */
  @include media-breakpoint-down(xs) {
    padding-block: rem-calc($sp-base * 2);

    &.unlocked {
      .city-item-img-wrapper::after {
        width: 120%;
      }
    }

    &-row {
      grid-template-columns: repeat(2, rem-calc(130px));
      grid-gap: unset;

      &-line {
        width: 55px;
        @include position($top: 110%, $zIndex: 1);

        &.right {
          right: rem-calc(38px);
        }

        &.left {
          left: rem-calc(38px);
        }
      }
    }

    &-box {
      right: -12%;
      bottom: -21%;
      transform: scale(.7);
    }

    &-img-wrapper {
      width: rem-calc(85px);
    }

    &-score {
      margin-top: rem-calc($sp-base);
    }

    &-btn {
      min-width: rem-calc(80px);
      margin-top: rem-calc($sp-base);
    }
  }

  /* 992px to 1440px */
  @include media-breakpoint-between(md, lg) {
    &-row {
      grid-gap: rem-calc(85px) rem-calc(40px);

      &-line {
        &.right {
          right: -1%;
        }

        &.left {
          left: -1%;
        }
      }
    }
  }
}
