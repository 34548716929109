@import url('https://fonts.googleapis.com/css2?family=Inter:wght@400;500;700;800&family=Ubuntu:wght@400;500;700&display=swap');

@font-face {
  font-family: Bebas;
  src:  url("../../fonts/bebas/bebasRegular.woff") format('woff2'),
  url("../../fonts/bebas/bebasRegular.woff") format('woff'),
  url("../../fonts/bebas/bebasRegular.ttf") format('ttf');
}


html,
body {
  font-size: $font-size-base; // 14
}

body {
  @include font($font-family-base, $font-weight-normal, $font-size-base);
  color: $body-color;
  background-color: $body-bg;
}

.container {
  width: 100%;
  padding-left: rem-calc($sp-base * 2);
  padding-right: rem-calc($sp-base * 2);
  margin-right: auto;
  margin-left: auto;
  @include make-container();
}

button {
  cursor: pointer;
}

h1, .h1 {
  font-size: rem-calc($font-size-lg);
}

h2, .h2 {
  margin-bottom: rem-calc($sp-base * 2);
  font-size: rem-calc($font-size-md);
}

.app {
  margin-bottom: rem-calc(100px);
}

// scrollbar
::-webkit-scrollbar {
  width: 5px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background: $scrollbar-truck;
  border-radius: rem-calc($scrollbar-radius);
}
::-webkit-scrollbar-thumb {
  border-radius: rem-calc($scrollbar-radius);
  background: $scrollbar-thumb;
}
::-webkit-scrollbar-thumb:hover {
  background: lighten($scrollbar-thumb, 10%);
}