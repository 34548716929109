.city-gift {
  .gift-content {
    width: 50%;
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: center;

    &-img {
      width: rem-calc(100px);
      margin-right: rem-calc($sp-base * 4);
    }

    &-title {
      margin-bottom: rem-calc($sp-base);
      @include font($font-family-base, $font-weight-bold, $font-size-base);
    }

    &-description {
      line-height: em-calc(17px);
    }
  }

  .gift-box {
    display: flex;
    justify-content: center;
    align-self: center;
    padding: rem-calc($sp-base);
    cursor: pointer;
    background-color: transparent;
    border-radius: rem-calc($radius-sm);
    transition: background-color .3s ease;

    &::after {
      content: "";
      display: block;
      padding-bottom: 100%;
    }

    &.active {
      flex-direction: column;
      align-items: center;
      padding: rem-calc($sp-base * 2);
      background: radial-gradient(50% 50% at 50% 50%, #161F5C 0%, #191B28 100%);
      box-shadow: 0 5.90769px 111px #8c0db3;

      .active-title {
        font-weight: $font-weight-bold;
      }

      .active-sub-title {
        font-size: rem-calc(11px);
        color: rgba($white, .5);
      }

      .active-img {
        width: rem-calc(50px);
        height: rem-calc(50px);
        object-fit: contain;
        margin-top: rem-calc($sp-base * 2);
      }

      .active-text {
        padding: $sp-base;
        margin-top: rem-calc($sp-base * 2);
        text-align: center;
        font-size: rem-calc($font-size-xs);
        line-height: $font-size-sm;
        background-color: $gray-300;
        border-radius: $sp-base;
      }

      &::after {
        padding-bottom: unset;
      }
    }

    &:not(.disabled):hover {
      background-color: $gray-900;
    }

    &.disabled {
      pointer-events: none;
    }

    &-img {
      min-width: rem-calc(48px);
      width: 60%;
      object-fit: contain;

      &.empty {
        width: 20%;
        min-width: rem-calc(50px);
      }
    }

    &-btn {
      min-width: rem-calc(200px);
      margin-block: rem-calc($sp-base);
    }

    &-wrapper {
      width: 100%;
      display: grid;
      grid-template-columns: repeat(3, 1fr);
      grid-gap: rem-calc($sp-base);
      margin: auto;
      padding: rem-calc($sp-base * 2);
      border-radius: rem-calc($radius-sm);
    }
  }

  /* 768px and bigger */
  @include media-breakpoint-up(sm) {
    .gift-box {
      width: 90%;

      &-img {
        min-width: rem-calc(120px);
      }

      &-btn {
        margin-block: rem-calc($sp-base * 3) rem-calc($sp-base);
      }

      &-wrapper {
        width: 85%;
        background-color: #272836;
      }
    }
  }

  /* 768px and smaller */
  @include media-breakpoint-down(sm) {
    .gift-box {
      background-color: #272836;
      border-radius: rem-calc($radius-sm);

      &-wrapper {
        width: 100%;
        grid-gap: rem-calc($sp-base);
      }
    }

    .gift-content {
      flex-direction: column;
      text-align: center;

      &-img {
        width: rem-calc(80px);
        margin-right: 0;
        margin-bottom: rem-calc($sp-base);
      }
    }
  }

  /* 576px and smaller */
  @include media-breakpoint-down(xs) {
    .gift-content-img {
      width: rem-calc(45px);
    }
  }

  /* 576px to 768px */
  @include media-breakpoint-between(xs, sm) {
    .gift-box-wrapper {
      width: 70%;
    }
  }
}
