.details-box {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  background-color: $gray-500;
  border-radius: rem-calc($sp-base);

  &-img {
    width: 100%;
    height: 100%;
    object-fit: contain;

    &-wrapper {
      width: rem-calc(100px);
      position: relative;

      .city-item-box-count {
        @include position($right: 0, $bottom: 25%);
      }
    }
  }

  &-name {
    margin-bottom: rem-calc($sp-base);
    @include font($font-family-base, $font-weight-bold, $font-size-base);
  }

  &-description {
    margin-bottom: rem-calc($sp-base * 2);
    font-size: rem-calc($font-size-xs);
  }

  &-btn {
    align-self: center;
    margin-top: auto;
  }

  &-wrapper {
    margin-top: rem-calc($sp-base * 10);
  }

  /* 768px and bigger */
  @include media-breakpoint-up(sm) {
    min-height: rem-calc(200px);
    padding: rem-calc(84px) rem-calc($sp-base * 2) rem-calc($sp-base * 2);

    &-wrapper {
      display: grid;
      grid-template-columns: repeat(3, rem-calc(224px));
      grid-gap: rem-calc($sp-base * 3);
    }

    &-img-wrapper {
      @include position(absolute, rem-calc(-30px), 0, $left: 0);
      margin-left: auto;
      margin-right: auto;
    }
  }

  /* 768px and smaller */
  @include media-breakpoint-down(sm) {
    padding: rem-calc($sp-base * 2);

    &-img-wrapper {
      margin-bottom: rem-calc($sp-base * 3);
    }

    + .details-box {
      margin-top: rem-calc($sp-base * 3);
    }
  }
}
