.w-100 {
  width: 100%;
}

.d-flex {
  display: flex;
}

.justify-content-end {
  justify-content: flex-end;
}

.justify-content-center {
  justify-content: center;
}

.justify-content-between {
  justify-content: space-between;
}

.align-items-center {
  align-items: center;
}

.flex-column {
  flex-direction: column;
}

.mt-auto {
  margin-top: auto;
}

.pl-base {
  padding-left: $sp-base;
}

.mt-16 {
  margin-top: rem-calc($sp-base * 2);
}

.mb-16 {
  margin-bottom: rem-calc($sp-base * 2);
}

.ml-16 {
  margin-left: rem-calc($sp-base * 2);
}

.text-primary {
  color: $primary;
}

.text-blue {
  color: $blue;
}

.text-center {
  text-align: center;
}

/* 768px and bigger */
@include media-breakpoint-up(sm) {
  .ml-sm-24-up {
    margin-left: rem-calc($sp-base * 3);
  }
}

/* 768px and smaller */
@include media-breakpoint-down(sm) {
  .mt-sm-24-down {
    margin-top: rem-calc($sp-base * 3);
  }
}

/* 576px and smaller */
@include media-breakpoint-down(xs) {
  .flex-xs-column-down {
    flex-direction: column;
  }
}

.overflow-hidden {
  overflow: hidden;
}
