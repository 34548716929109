.progress {
  width: 100%;
  height: rem-calc($progress-height);
  @extend .px-1, .py-1;
  background-color: $progress-bg;
  border-radius: rem-calc($radius-sm);

  &-data {
    display: flex;
    align-items: center;
    margin-bottom: rem-calc($sp-base);
  }

  &-icon {
    font-size: rem-calc(18px);
    color: $primary;
  }

  &-text {
    margin-left: rem-calc(12px);
    font-size: rem-calc($font-size-xs);
  }

  &-bar {
    width: 0;
    height: 100%;
    background: $progress-bar-bg;
    border-radius: rem-calc($radius-sm);
   transition: width .3s linear;
  }

  &-wrapper + .progress-wrapper {
    margin-left: rem-calc(28px);
  }

  /* 992px and smaller */
  @include media-breakpoint-down(md) {
    background-color: $gray-500;
  }
}
