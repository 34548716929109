.section-tour {
  margin-bottom: rem-calc($sp-base * 3);
}

.tour-list-item {
  margin-bottom: rem-calc($sp-base * 4);

  .card-btn {
    margin-top: auto;
  }
}

/* 768px and bigger */
@include media-breakpoint-up(sm) {
  .section-tour {
    margin-bottom: rem-calc($sp-base * 8);
  }

  .tour-list-item {
    display: flex;

    + .tour-list-item {
      margin-bottom: rem-calc($sp-base * 3);
    }
  }
}
