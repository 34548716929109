.steps {
  width: 100%;
  display: flex;
  justify-content: center;
  align-self: center;
  cursor: pointer;

  .step {
    width: 100%;
    height: rem-calc($step-height);
    position: relative;
    background-color: $step-bg-color;
    border-radius: rem-calc($step-radius);

    &::after {
      content: "";
      width: rem-calc($step-point-width);
      height: rem-calc($step-point-width);
      @include position(absolute, 50%, $right: -7px, $zIndex: -1);
      transform: translateY(-50%);
      background-color: $step-bg-color;
      border-radius: $radius-circle;
    }

    &:first-child .step-helper {
      left: rem-calc($step-height / 2);
    }

    &-helper {
      width: 0;
      height: rem-calc($step-height / 2);
      @include position(absolute, 50%, $zIndex: 2);
      display: inline-block;
      transform: translateY(-50%);
      transition: width .3s ease;
      background-color: $step-active-bg-color;
      border-radius: rem-calc($step-radius);
    }

    &-active-circle {
      width: rem-calc($step-point-width - 6);
      height: rem-calc($step-point-width - 6);
      @include position(absolute, 50%, $right: -4px, $zIndex: 1);
      transform: translateY(-50%);
      border-radius: $radius-circle;

      &::after {
        content: "";
        width: rem-calc($step-icon-size);
        height: rem-calc($step-icon-size);
        @include position(absolute, rem-calc(-($step-icon-size + $sp-base)), $left: 50%);
        transform: translateX(-50%);
        background: url("../../../images/star-deactive.svg") no-repeat;
      }

      &::before {
        content: attr(data-star);
        @include position(absolute, calc(100% + (#{$sp-base} * 2)), $left: 50%);
        transform: translateX(-50%);
        @include font($font-family-serif, $font-weight-black, $font-size-xs);
      }
    }

    + .step,
    + .step .step-helper {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }

    &.passed {
      .step-active-circle {
        background-color: $step-active-bg-color;

        &::after {
          background: url("../../../images/star-active.svg") no-repeat;
        }
      }
    }

    &.has-tooltip .step-helper {
      &::before {
        content: attr(data-progress);
        @include position(absolute, 20px, $left: 50%);
        transform: translateX(-50%);
        min-width: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        padding: $sp-base / 2 $sp-base;
        background-color: rgb($black, 80%);
        box-shadow: 0 0 12px rgb($black, 60%);
        border-radius: $radius-xs;
        opacity: 0;
      }

      &::after {
        content: "";
        @include position(absolute, 15px, $left: 50%);
        transform: translateX(-50%);
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid rgb($black, 80%);
        opacity: 0;
      }
    }
  }

  &:hover .step.has-tooltip .step-helper {
    &::after,
    &::before {
      opacity: 1;
    }
  }

  /* 992px and smaller */
  @include media-breakpoint-down (md) {
    width: 85%;
  }
}
