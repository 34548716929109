.shop-basket {
  height: fit-content;
  @extend .px-3, .py-3;
  color: $white;
  background-color: $gray-700;
  border-radius: rem-calc($radius-base);

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-bottom: rem-calc($sp-base * 2);
    border-bottom: 1px solid $gray-600;
  }

  &-footer {
    @extend .py-3;
    border-top: 1px solid $gray-600;
  }

  &-btn {
    width: 100%;
    margin-top: rem-calc($sp-base * 2);
  }

  &-item {
    display: flex;
    align-items: center;

    .basket-item {

      &-img-wrapper {
        width: rem-calc(80px);
        height: rem-calc(80px);
        min-width: rem-calc(80px);
        margin-right: rem-calc($sp-base * 2);
        border-radius: rem-calc($radius-base);
      }

      &-img {
        width: 100%;
        height: 100%;
        object-fit: cover;
        border-radius: rem-calc($radius-base);
      }
    }
  }

  &-empty-text {
    margin-block: rem-calc($sp-base * 5);
    text-align: center;
  }


  /* 992px and smaller */
  @include media-breakpoint-down(md) {
    margin-top: rem-calc($sp-base * 2);
  }
}