.navbar {
  height: rem-calc($navbar-height);
  display: flex;
  justify-content: space-between;
  align-items: center;
  @include position(sticky, 0, $zIndex: 99);
  @extend .px-3, .my-3;
  background-color: $navbar-bg;
  border-radius: rem-calc($radius-base);

  &-tour-name {
    text-transform: capitalize;
  }

  &-btn {
    margin-inline: rem-calc(26px);
  }

  &-btn-icon {
    font-size: rem-calc(18px);
  }

  &-hamburger-btn {
    padding-inline: 0;
    font-size: rem-calc(16px);
    z-index: $navbar-zIndex + 1;
  }

  /* 992px and bigger */
  @include media-breakpoint-up(md) {
    line-height: em-calc($line-height-md);

    &-btn-icon {
      &.active {
        color: $primary;
      }
    }
  }

  /* 992px and smaller */
  @include media-breakpoint-down(md) {
    margin-left: -16px;
    margin-right: -16px;
    margin-top: 0;
    border-radius: 0;

    &-tour-name {
      display: inline-block;
      padding: rem-calc($sp-base * 3) ;
      font-size: rem-calc(16px);
    }
  }

}

.aside-navbar {
  width: 0;
  height: 100%;
  @include position(fixed, 0, $bottom: 0, $left: 0, $zIndex: 99);
  opacity: 0;
  visibility: hidden;
  transition: .3s ease width;
  background-color: rgba($black, 0.8);

  &-close-btn {
    @include position(absolute, 28px, 36px);
  }

  &-content {
    width: 75%;
    height: 100%;
    background-color: $gray-500;
    border-top-right-radius: $sp-base;
    border-bottom-right-radius: $sp-base;
  }

  &-list {
    margin-block: rem-calc(60px);
    margin-left: rem-calc(25px);

    .aside-list-item {
      display: flex;
      align-items: center;
      cursor: pointer;
      font-size: rem-calc($font-size-xs);
      font-weight: $font-weight-normal;
      color: #cccccc;
      opacity: 0;
      transform: translate3d(-100%, 0, 0);

      &.active {
        color: $white;
        font-weight: $font-weight-bold;

        .aside-list-item-icon {
          background-color: $navbar-bg;
        }
      }

    }

    .aside-list-item-icon {
      min-width: rem-calc(32px);
      height: rem-calc(32px);
      display: flex;
      align-items: center;
      justify-content: center;
      margin-right: rem-calc($sp-base * 2);
      font-size: rem-calc(20px);
      border-radius: rem-calc($radius-sm);
    }

    .aside-list-item-text {
      width: 100%;
      height: 100%;
      display: inline-block;
      padding-block: rem-calc($sp-base * 2);
      border-bottom: 1px solid $navbar-bg;
    }
  }

  .navbar-btn {
    min-width: rem-calc(192px);
    font-size: rem-calc($font-size-xs);
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  .navbar-tour-name {
    opacity: 0;
    transform: translate3d(-100%, 0, 0);
  }

  &.opened {
    width: 100%;
    opacity: 1;
    visibility: visible;

    .aside-list-item {
      @include animation(fadeInLeft);

      @for $i from 1 through 5 {
        &:nth-child(#{$i}) {
          animation-delay: $i * 100ms;
        }
      }
    }

    .navbar-btn {
      @include animation(fadeInLeft, $delay: 700ms);
    }

    .navbar-tour-name {
      @include animation(fadeInLeft, $delay: 50ms);
    }
  }
}
