.progress-total-stars,
.progress-total-cities {
  width: rem-calc(128px);
}

// shop page
.shop-wrapper {
  display: grid;
  grid-column-gap: rem-calc($sp-base * 2);

  /* 992px and bigger */
  @include media-breakpoint-up(md) {
    grid-template-columns: auto rem-calc(305px);
  }
}

.svg-loader {
  width: 100%;
  height: 100%;
}

// portrait
.portrait {
  @include position(fixed, 0, 0, 0, 0, 100);
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $body-bg;

  .portrait-lottie-wrapper {
    transform: rotate(-90deg);
  }
}

/////////
.alert-enter {
  opacity: 0;
  transform: scale(0.9);
}

.alert-enter-active {
  opacity: 1;
  transform: translateX(0);
  transition: opacity 300ms, transform 300ms;
}

.alert-exit {
  opacity: 1;
}

.alert-exit-active {
  opacity: 0;
  transform: scale(0.9);
  transition: opacity 300ms, transform 300ms;
}

.fade-enter {
  opacity: 0;
}

.fade-exit {
  opacity: 1;
}

.fade-enter-active {
  opacity: 1;
}

.fade-exit-active {
  opacity: 0;
}

.fade-enter-active,
.fade-exit-active {
  transition: opacity 500ms;
}

.suitcase-icon {
  display: inline-block;
  background-image: url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAYAAAAWGF8bAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAABqSURBVHgB5ZJBDoAgDAQXwwP8cf0BT9UfrG1iEw+mGOzBhEm4lO5ACoBCUnTtHMeyDZesMY+tmF29K3I4TEgksiCZdGGNNovyVP88Jn/CN701ahy5YThDzYmLXe61KNRD7gf0mif8h/8Xnm1NBCIHlsdgAAAAAElFTkSuQmCC');
  width: 20px;
  height: 16px;
}
