.city-details {
  .city-details-content {
    display: flex;
    align-items: center;

    .city-details-description {
      line-height: em-calc(17px);
    }
  }

  .city-details-cover-img {
    height: rem-calc(360px);
    @include position(absolute, 0, 0, $left: 0, $zIndex: -1);

    &::after {
      content: "";
      @include position(absolute, 0, 0, -1px, 0);
      background: linear-gradient(2.24deg, $popup-bg 6%, rgba(25, 27, 40, 0) 200%);
    }

    &::before {
      content: "";
      width: 100%;
      height: 100%;
      @include position(absolute, 0, $left: 0);
      background: radial-gradient(23.26% 44.79% at 29.36% 56.21%, #3b43b0 0%, rgba(39, 40, 54, 0) 100%);
    }
  }

  .city-details-btn-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-block: rem-calc($sp-base * 4);

    .btn {
      width: 100%;
      max-width: rem-calc(280px);
    }
  }

  .city-details-score-wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: rem-calc(40px) auto rem-calc(60px) auto;
  }

  /* 768px and bigger */
  @include media-breakpoint-up(sm) {
    .city-details-content {
      width: 80%;
      display: flex;
      margin: auto;
    }

    .city-details-img {
      width: rem-calc(205px);
      height: rem-calc(180px);
      object-fit: contain;
    }

    .city-details-score-wrapper {
      width: 55%;
    }
  }

  /* 768px and smaller */
  @include media-breakpoint-down(sm) {
    .city-details-content {
      flex-direction: column;
      text-align: center;
    }

    .city-details-img {
      width: rem-calc(100px);
    }
  }
}
