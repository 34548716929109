.nav {
  display: flex;
  align-items: center;

  &-tabs {
    height: rem-calc($nav-tab-height);
    min-width: rem-calc($nav-tab-mWidth);
    display: flex;
    justify-content: space-between;
    padding-inline: rem-calc(2px);
    @extend .py-1;
    border-radius: rem-calc($radius-xs);
    color: $white;
    background-color: $nav-tab-bg;

    .nav-item {
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      margin-inline: rem-calc(2px);
      padding-inline: rem-calc($sp-base);
      font-size: rem-calc($font-size-xs);
      border-radius: rem-calc($radius-xs);
      transition: color .3s linear, background-color .3s linear;

      &:hover,
      &.active {
        color: $nav-tab-color;
        background-color: $white;
      }

      &:not(.active) {
        cursor: pointer;
      }

      // TODO: remove after adding routing
      &:nth-child(4),
      &:nth-child(5) {
        display: none;
      }
    }
  }
}
