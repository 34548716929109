/* position type */
@mixin position(
  $type: null,
  $top: null,
  $right: null,
  $bottom: null,
  $left: null,
  $zIndex: null
) {
  @if $type {
    position: $type;
  }
  @if $top {
    top: $top;
  }
  @if $right {
    right: $right;
  }
  @if $bottom {
    bottom: $bottom;
  }
  @if $left {
    left: $left;
  }
  @if $zIndex {
    z-index: $zIndex;
  }
}

/* media breakpoint up, min-width */
@mixin media-breakpoint-up($name, $breakpoints: $breakpoints) {
  $min: breakpoint-min($name, $breakpoints);
  @if $min {
    @media (min-width: $min) {
      @content;
    }
  } @else {
    @content;
  }
}

/* media breakpoint down, max-width */
@mixin media-breakpoint-down($name, $breakpoints: $breakpoints) {
  $max: breakpoint-max($name, $breakpoints);
  @if $max {
    @media (max-width: $max) {
      @content;
    }
  } @else {
    @content;
  }
}

/* media breakpoint between, min-width and max-width */
@mixin media-breakpoint-between($lower, $upper, $breakpoints: $breakpoints) {
  $min: breakpoint-min($lower, $breakpoints);
  $max: breakpoint-max($upper, $breakpoints);

  @if $min != null and $max != null {
    @media (min-width: $min) and (max-width: $max) {
      @content;
    }
  } @else if $max == null {
    @include media-breakpoint-up($lower, $breakpoints) {
      @content;
    }
  } @else if $min == null {
    @include media-breakpoint-down($upper, $breakpoints) {
      @content;
    }
  }
}

/* make container */
@mixin make-container() {
  @each $breakpoint, $container-max-width in $container-max-widths {
    @include media-breakpoint-up($breakpoint, $breakpoints) {
      max-width: $container-max-width;
    }
  }
}

/* font */
@mixin font($family: null, $weight: null, $size: null, $line-height: em-calc($line-height-base)) {
  @if $family {
    font-family: $family;
  }
  @if $weight {
    font-weight: $weight;
  }
  @if $size {
    font-size: $size;
  }
  @if $line-height {
    line-height: $line-height;
  }
}

@mixin animation($name: null, $timingFunction: ease, $fillMode: forwards, $duration: 500ms, $delay: 0ms) {
  @if $name {
    animation-name: $name;
  }
  @if $timingFunction {
    animation-timing-function: $timingFunction;
  }
  @if $fillMode {
    animation-fill-mode: $fillMode;
  }
  @if $duration {
    animation-duration: $duration;
  }
  @if $delay {
    animation-delay: $delay;
  }
}
