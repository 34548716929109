.history {
  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-block: rem-calc($sp-base * 3);
  }

  &-nav-tabs {
    background-color: #191b28;
  }

  &-progress {
    width: rem-calc(198px);
    display: flex;

    .progress {
      margin-left: rem-calc($sp-base);
      background-color: #191b28;

      &,
      &-bar {
        border-radius: rem-calc($radius-sm / 2);
      }
    }
  }

  &-table {
    td > div {
      display: flex;
      align-items: center;
      min-width: rem-calc(200px);
    }

    .td-boxes {
      display: flex;
      align-items: center;

      .boxes-item {
        position: relative;
        display: flex;
        align-items: center;

        span {
          display: inline-block;
          margin-inline: rem-calc($sp-base);
        }

        .icon-box {
          font-size: rem-calc(16px);
        }

        &:not(:last-child)::after {
          content: "";
          width: 1px;
          height: rem-calc(14px);
          @include position(absolute, 50%, 0);
          transform: translateY(-50%);
          background-color: $white;
        }

        .box-count {
          padding: rem-calc($sp-base / 2);
          background-color: $gray-500;
          border-radius: rem-calc($radius-rounded);
        }
      }
    }

    .td-city-name,
    .td-prize-name,
    .td-price,
    .td-from {
      img {
        width: rem-calc(32px);
        height: rem-calc(32px);
        margin-right: rem-calc($sp-base * 2);
        border-radius: rem-calc($sp-base);
      }
    }

    .td-city-name .img-wrapper {
      position: relative;

      .completed {
        width: rem-calc(16px);
        height: rem-calc(16px);
        @include position(absolute, $right: rem-calc($sp-base), $bottom: 0);
        display: inline-flex;
        align-items: center;
        justify-content: center;
        font-size: rem-calc(10px);
        background-color: $success;
        border-radius: $radius-circle;
      }
    }

    .td-stars {
      img {
        width: rem-calc(20px);

        + img {
          margin-left: rem-calc($sp-base);
        }
      }
    }
  }

  + .history {
    margin-block: rem-calc($sp-base * 7) rem-calc($sp-base * 3);
  }

  /* 1440px and smaller */
  @include media-breakpoint-down(lg) {
    &-table .td-prize-name {
        min-width: rem-calc(350px);
      }
  }

  /* 992px and smaller */
  @include media-breakpoint-down(md) {
    &-header {
      flex-direction: column;
      align-items: flex-start;
    }

    &-nav-tabs {
      margin-block: rem-calc($sp-base * 3);
    }
  }

  /* 576px and smaller  */
  @include media-breakpoint-down(xs) {
    &-progress + .history-progress {
      margin-left: 0;
      margin-top: rem-calc($sp-base * 3);
    }
  }
}
