// colors
$white: #fff;
//$gray-100: #f8f9fa !default;
$gray-200: #eeecff !default;
$gray-300: #393D5B !default;
$gray-400: #0d0d16 !default;
$gray-500: #272836 !default;
$gray-600: #595959 !default;
$gray-700: #333144 !default;
$gray-800: #30323d !default;
$gray-900: #020015 !default;
$black: #000;

$body-color: $white;
$body-bg: #020015;

$yellow: #ffd02f;
$green: #71f134;
$red: #c63333;
$blue: #65d1ff;


$primary: $yellow;
$secondary: $gray-800;
$success: $green;
$danger: $red;

$theme-colors: (
        "primary":    $primary,
        "secondary":  $secondary,
        "success":    $success,
        "danger":     $danger,
) !default;

// fonts
$font-family-base: "Ubuntu", sans-serif;
$font-family-serif: "Inter", sans-serif;
$font-family-3d: "Bebas", sans-serif;

$font-weight-normal: 400;
$font-weight-medium: 500;
$font-weight-bold: 700;
$font-weight-black: 800;

$font-weight-base: $font-weight-medium;

$line-height-base: 22px;
$line-height-sm: 14px;
$line-height-md: 18px;


// spaces
$spacer: 8px !default;
$spacers: (
        1: $spacer / 2, // 4
        2: $spacer, // 8
        3: $spacer * 2, // 16
        4: $spacer * 3 // 24
);

$sp-base: 8px;

// screens
$breakpoints: (
        xs: 576px,
        sm: 768px,
        md: 992px,
        lg: 1440px,
        xl: 1920px
);

$container-max-widths: (
        xs: 540px,
        sm: 720px,
        md: 1092px,
        lg: 1300px, // 1140px,
        xl: 1300px
);


// font sizes
$font-size-xs: 12px;
$font-size-sm: 14px;
$font-size-md: 24px;
$font-size-lg: 32px;
$font-size-xl: 40px;

$font-size-base: $font-size-sm; // 14

// border radius
$radius: 16px;
$radius-xs: 4px;
$radius-sm: 8px;
$radius-md: 32px;
$radius-rounded: 23px;
$radius-circle: 50%;

$radius-base: $radius;

// navbar
$navbar-height: 64px;
$navbar-bg: #191b28;
$navbar-zIndex: 99;

// nav tab
$nav-tab-mWidth: 205px;
$nav-tab-height: 40px;
$nav-tab-bg: $gray-400;
$nav-tab-color: $gray-400;

// buttons
$btn-height: 40px;
$btn-height-xs: 24px;
$btn-height-sm: 32px;

// progress
$progress-bg: $gray-900;
$progress-height: 18px;
$progress-bar-bg: linear-gradient(270deg, #1AFF23 -94.34%, #1BBAFF -27.07%, #D459FF 31.5%, #FE6060 100%);

// popups
$popup-bg: #191b28;
$popup-zIndex: $navbar-zIndex + 3;
$popup-close-btn-size: 36px;

// progress steps
$step-height: 8px;
$step-bg-color: #020015;
$step-active-bg-color: $primary;
$step-radius: 22px;
$step-point-width: 16px;
$step-icon-size: 24px;

// table
$table-border-color: #4d4d4d;

// scrollbar
$scrollbar-radius: 10px;
$scrollbar-truck: #191b28;
$scrollbar-thumb: #32354b;
$scrollbar-thumb-hover: #191b28;

// accordion
$accordion-bg: rgba(34, 38, 56, 0.9);
$accordion-shadow: 0 8px 24px -8px rgba(17, 18, 37, 0.25);

$star-width: 22px;

