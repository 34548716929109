.popup {
  width: 100%;
  max-height: 99vh;
  padding: rem-calc($sp-base * 2);
  @include position(relative, $zIndex: 1);
  overflow: hidden;
  overflow-y: auto;
  background-color: $popup-bg;
  border-radius: rem-calc($radius-base);

  &-overlay {
    @include position(fixed, 0, 0, 0, 0, $popup-zIndex);
    background-color: rgba($black, .6);
  }

  &-btn {
    width: rem-calc($popup-close-btn-size);
    height: rem-calc($popup-close-btn-size);
    margin-left: auto;
    background-color: #272836;
    border-radius: $radius-circle;
  }

  &-sm .popup {
    max-width: rem-calc(500px);
    min-height: rem-calc(300px);

    &-body {
      min-height: calc(300px - #{$popup-close-btn-size} - #{$sp-base * 4});
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  /* 992px and bigger */
  @include media-breakpoint-up(md) {
    &-overlay {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }

  /* 768px and bigger */
  @include media-breakpoint-up(sm) {
    max-width: rem-calc(768px);
  }

  /* 768px and smaller */
  @include media-breakpoint-down(sm) {
    max-height: 90%;
    overflow-y: auto;
  }
}
