.star {
  width: rem-calc($star-width);
  height: rem-calc($star-width);
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: rem-calc($star-width);
    height: rem-calc($star-width);
    @include position(absolute, $right: 0, $left: 0);
    margin-left: auto;
    object-fit: contain;
  }

  .star-active,
  .star-deactivate {
    @include position($top: -2%, $left: 12%);
    transform: scale(.8);
  }

  :where(.star-active, .chip-active, .star-deactivate, .chip-deactivate) {
    animation-duration: 6s;
    animation-timing-function: linear;
    animation-fill-mode: forwards;
  }

  :where(.star-active, .chip-active) {
    opacity: 0;
    animation-delay: 2s;
  }

  :where(.star-deactivate, .chip-deactivate) {
    opacity: 1;
  }

  .animation-star &.has-animation {
    .chip-deactivate {
      animation-name: chipDeactivateAnimation;
    }

    .star-deactivate {
      animation-name: starDeactivateAnimation;
    }

    .star-active {
      animation-name: starActiveAnimation;
    }

    .chip-active {
      animation-name: chipActiveAnimation;
    }
  }

  &.active {
    :where(.star-deactivate, .chip-deactivate) {
      animation: ease 2s forwards fadeOut;
    }

    :where(.star-active, .chip-active) {
      animation: ease 2s forwards fadeIn;
    }
  }

  @keyframes starDeactivateAnimation {
    0% {
      transform: rotate(0deg) scale(.5);
    }
    40% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    90% {
      opacity: 0;
    }
    100% {
      transform: rotate(360deg) scale(.5);
      opacity: 0;
    }
  }

  @keyframes chipDeactivateAnimation {
    0% {
      transform: rotate(0deg);
    }
    40% {
      opacity: 1;
    }
    50% {
      opacity: 0;
    }
    90% {
      opacity: 0;
    }
    100% {
      transform: rotate(-360deg);
      opacity: 0;
    }
  }

  @keyframes starActiveAnimation {
    0% {
      transform: rotate(0deg) scale(.5);
    }
    30% {
      opacity: 1;
    }
    100% {
      opacity: 1;
      transform: rotate(360deg) scale(.5);
    }
  }

  @keyframes chipActiveAnimation {
    0% {
      opacity: 1;
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(-360deg);
      opacity: 1;
    }
  }

  /* 576px and smaller */
  @include media-breakpoint-down(xs){
    transform: scale(.7);
  }
}
