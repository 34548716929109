.casino-city-tour-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-block: $spacer * 8;
  text-align: center;

  .title {
    position: relative;
    flex-grow: 0;
    margin-bottom: 0;
    white-space: nowrap;

    &::after,
    &::before {
      content: "";
      width: 24px;
      height: 33px;
      @include position(absolute, 50%);
      transform: translateY(-50%);
      background: url("/assets/images/chevron-left.svg") no-repeat center / contain;
    }

    &::after {
      right: -24px;
      transform: translateY(-50%) rotate(180deg);
    }

    &::before {
      left: -24px;
    }

    &-wrapper {
      display: flex;
      align-items: center;
    }
  }

  .line {
    height: 3px;
    flex: 1;
    border-radius: 25px;
    background-color: #65d1ff;

    &.left {
      margin-right: 48px;
    }

    &.right {
      margin-left: 48px;
    }
  }


  .text {
    margin-top: $spacer * 2;
  }

  // desktop
  @include media-breakpoint-up(lg) {
    max-width: 1440px;
    padding-inline: $spacer * 2;
    margin-inline: auto;

    .title {
      padding-inline: $spacer * 5;
      font-size: $font-size-xl;
    }
  }

  // mobile
  @include media-breakpoint-down(lg) {
    .title {
      padding-inline: $spacer * 2;
      font-size: $font-size-md;
    }

    .page-wrapper {
      padding: 0;
    }
  }
}
