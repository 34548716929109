/* horizontal paddings */
@each $name, $sp in $spacers {
  .px-#{$name} {
    padding-inline: rem-calc($sp);
  }
}
/* vertical paddings */
@each $name, $sp in $spacers {
  .py-#{$name} {
    padding-block: rem-calc($sp);
  }
}
/* vertical margins */
@each $name, $sp in $spacers {
  .my-#{$name} {
    margin-block: rem-calc($sp);
  }
}

/* buttons variants */
@each $color, $value in $theme-colors {
  .btn-#{$color} {
    color: $white;
    background-color: $value;
    transition: background-color .25s ease;

    @if $color == "primary" {
      color: $gray-900;
    }

    &:hover,
    &.active {
      color: darken($white, 3%);
      background-color: darken($value, 3%);

      @if $color == "primary" {
        color: darken($gray-900, 3%);
      }
    }
  }

  .btn-outline-#{$color} {
    color: $value;
    border: 1px solid $value;
    transition: color .3s ease, background-color .3s ease;

    &:not(:disabled):hover,
    &:not(:disabled).active {
      color: $gray-900;
      background-color: $value;
    }
  }
}
