@font-face {
  font-family: 'casinoCity';
  src:  url('fonts/casinoCity.eot?bwbo1q');
  src:  url('fonts/casinoCity.eot?bwbo1q#iefix') format('embedded-opentype'),
    url('fonts/casinoCity.ttf?bwbo1q') format('truetype'),
    url('fonts/casinoCity.woff?bwbo1q') format('woff'),
    url('fonts/casinoCity.svg?bwbo1q#casinoCity') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'casinoCity' !important;
  speak: never;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-map-mobile:before {
  content: "\e901";
}
.icon-history:before {
  content: "\e90a";
}
.icon-cart:before {
  content: "\e909";
}
.icon-check:before {
  content: "\e908";
}
.icon-hamburger:before {
  content: "\e907";
}
.icon-box:before {
  content: "\e900";
}
.icon-close:before {
  content: "\e902";
}
.icon-delete:before {
  content: "\e903";
}
.icon-info:before {
  content: "\e904";
}
.icon-map:before {
  content: "\e905";
}
.icon-star:before {
  content: "\e906";
}
