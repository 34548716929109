.btn {
  display: flex;
  justify-content: center;
  align-items: center;
  @extend .px-3;
  @include font($font-family-base, $font-weight-medium, $font-size-sm);

  &:not(.btn-icon) {
    border-radius: rem-calc($radius-sm);
  }

  &-base {
    height: rem-calc($btn-height);
  }

  &-xs {
    height: rem-calc($btn-height-xs);
    @extend .py-1;
    font-size: rem-calc($font-size-xs);
  }

  &-sm {
    height: rem-calc($btn-height-sm);
    @extend .py-2;
  }

  &-outline-secondary {
    @extend .py-2;
    color: $white;
    border: 1px solid $gray-600;
  }
}

.btn[disabled],
button[disabled] {
  filter: grayscale(1);
  opacity: .5;
  cursor: default;
}
