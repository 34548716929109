.accordion {
  &-item {
    padding: $spacer * 3;
    margin-bottom: $spacer * 3;
    color: $white;
    background-color: $accordion-bg;
    box-shadow: $accordion-shadow;
    border-radius: $radius;

    &.toggled {
      .item-header-btn .icon {
        transform: rotate(180deg);
      }
    }
  }

  .item-header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-title {
      font-family: $font-family-base;
      font-weight: $font-weight-base;
    }

    &-btn {
      width: 32px;
      height: 32px;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: #30323D;
      border-radius: $radius-sm;

      .icon {
        width: 45%;
        height: 45%;
        transition: transform .25s linear;
      }
    }
  }

  &-collapse {
    margin-top: $spacer * 2;
  }

}