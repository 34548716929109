.terms-conditions {
  margin: auto;

  &-title {
    text-align: center;
    font-size: rem-calc($font-size-lg);
    line-height: em-calc(37px);
  }

  /* 992px and bigger */
  @include media-breakpoint-up(md) {
    width: 70%;
  }
}

.section-terms-conditions {
  margin-bottom: rem-calc($sp-base * 3);
}
