.city-tops {
  display: flex;
  height: 525px;

  &-block {
    width: 100%;
    padding-block: $spacer * 3;
    background-color: #222638;
    border-radius: $radius;
  }

  .tops-title {
    padding-inline: $spacer * 3;
    @include font($font-family-base, $font-weight-bold, 16px);
  }

  .tops-item {
    display: flex;
    color: $white;
    background-color: #313549;
    border-radius: $radius;

    + .tops-item {
      margin-top: $spacer * 2;
    }

    &-id {
      margin-top: calc($spacer / 2);
      @include font($font-family-base, $font-weight-normal, $font-size-xs, 14px);
      color: #98989d;
    }

    &-wrapper {
      max-height: 420px;
      overflow-y: auto;

      &::-webkit-scrollbar-track {
        background: transparent;
      }
    }

  }

  // desktop
  @include media-breakpoint-up(md) {
    column-gap: $spacer * 3;

    .tops-item-wrapper {
      padding-inline: $spacer * 3;
    }
  }

  // mobile
  @include media-breakpoint-down(md) {
    flex-direction: column;

    .tops-item-wrapper {
      padding-inline: $spacer * 2;
    }
    + .casino-city-tour-current-city {
      margin-top: $spacer * 3;
    }

  }
}

.city-tops-shopping {
  margin-bottom: $spacer * 2;

  &-item {
    padding: $spacer * 2;

    &-img {
      width: 140px;
      height: 96px;
      margin-right: $spacer * 2;
      object-fit: cover;
      border-radius: $radius-sm;
    }

    &-title {
      @include font($font-family-base, $font-weight-bold, $font-size-md);
    }

    &-prize {
      margin-block: 14px;
      color: #98989d;
    }

    &-chip {
      width: 12px;
      height: 12px;
      margin-inline: $spacer;
    }

  }

  // mobile
  @include media-breakpoint-down(lg) {
    .top-shopping-item {
      &-img {
        width: 96px;
        height: 96px;
      }
    }
  }
}

.city-tops-prizes {
  margin-bottom: $spacer * 2;

  .top-prizes-item {
    padding: $spacer;

    &-img {
      width: 40px;
      height: 40px;
      margin-right: $spacer;
      object-fit: cover;
      border-radius: $radius-sm;
    }

    &-title {
      @include font($font-family-base, $font-weight-bold, 16px, 18px);
    }
  }

  // mobile
  @include media-breakpoint-down(md) {
    margin-top: $spacer * 2;

    .top-prizes-item {
      &-title {
        font-size: 10px;
      }
    }
  }
}
